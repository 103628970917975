@import "../../Less/_brand-colors";
@import "../../Less/_typography";
@import "../../Less/Mixins/_box-shadow-style";

.radio-checkbox-label-style() {
  color: @text-color !important;
  font-family: @text-font !important;
  font-size: @font-size-stand !important;
}

.radio-style {
  &.ant-radio-checked {
    .ant-radio-inner {
      background: @secondary !important;
      border-color: @secondary !important;

      &::after {
        background-color: @white !important;
      }
    }
  }

  &:hover {
    .ant-radio-inner {
      border-color: @secondary !important;
    }
  }

  .ant-radio-input:focus + .ant-radio-inner {
    .box-shadow(focus);
  }

  + span {
    .radio-checkbox-label-style();
  }
}
