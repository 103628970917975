@import "../../Less/_default-variables";
@import "./_make-col";

.generate-columns (@i) when (@i > 0) {
  display: flex;
  flex-wrap: wrap;

  &.has-@{i}-columns {
    > * {
      @columnSet: (12 / @i);
      .make-col(@columnSet);

      margin: 0 0 0.5rem 0 !important;

      @media @min769 {
        margin: 0 !important;
      }
    }
  }
  .generate-columns(@i - 1);
}
