@import "../../../Less/_default-variables";
@import "../../../Less/_brand-colors";

.theme-offer-option {
  border: 0.1rem solid @border-color;
  display: flex;
  flex-direction: column;
  margin: @gap-medium 0;

  @media @max768 {
    margin: @gap-base 0;
  }

  .ant-radio-wrapper {
    background: @grey-bg-color;
    margin: 0;
    padding: @gap-base @gap-medium;
  }

  .theme-fields {
    padding: @gap-medium;

    .theme-field {
      margin-top: 0;

      &.total-loan {
        border-top: 1px solid @border-color;
        margin-bottom: 0;
        padding-top: @gap-base;
      }
    }
  }

  @media @min481 {
    .theme-field {
      align-items: center;
      display: flex;

      > * {
        flex-basis: 50%;

        &.theme-field-title {
          margin-bottom: 0;
        }
      }
    }
  }

  .selection {
    background: @primary;
    color: @white;
    display: none;
    padding: @gap-sm;
    text-align: center;
  }
  &.selected {
    border-color: @primary;
    .selection {
      display: block;
      opacity: 1;
    }
  }
}

@primary-color: #012a64;@primary: #012a64;@secondary: #056e47;@tertiary: #056e47;@tertiary-dark: #056e47;@quaternary: #24B3D5;@grey-light: #E3E3E3;@grey-white: #F4F4F4;@alert: #FDE6F1;@menu-color: #056e47;@menu-active-color: #033d28;@heading-text-color: #012a64;@text-color: #5d5d5d;@input-placeholder-color: #5d5d5d;@link-color: #24B3D5;@link-hover-color: #44C0D6;@2column-bg: #012a64;@step-icon-active: #012a64;@border-color: #E3E3E3;@icon-bg-color: #E3E3E3;@grey-bg-color: #F4F4F4;@grey-bg-darker-color: #E3E3E3;@heading-1-size: 2.375rem;@heading-2-size: 2.375rem;@heading-3-size: 1.8rem;@heading-4-size: 1.5rem;@heading-5-size: 1.3rem;@font-size-stand: 1.0625rem;@font-size-md: 1.125rem;@font-size-sm: 0.875rem;@font-size-xs: 0.875rem;@font-size-xxs: 0.75rem;@font-size-label: 0.875rem;@font-size-button: 1.0625rem;@font-size-step-title: 1.25rem;@font-size-input: 0.875rem;@heading-font: 'Avenir LT Black', sans-serif;@text-font: 'Avenir LT Light', sans-serif;@label-font: 'Avenir LT Black', sans-serif;@step-title-font: 'Avenir LT Black', sans-serif;@button-font: 'Avenir LT Black', sans-serif;@small-font: 'Avenir LT Light', sans-serif;@menu-font: 'Avenir LT Light', sans-serif;@badge-line-height-0: 2.4;@badge-line-height: 2rem;