@import "../_brand-colors";
@import "../_default-variables";
@import "./_alignfull";

.bg-2column-style() {
  .alignfull();

  align-items: stretch;
  height: 100%;

  .theme-columns-wrapper {
    align-items: stretch;
    display: flex;
    flex-wrap: wrap;
    height: 100%;
    margin-left: 0 !important;
    margin-right: 0 !important;

    @media @max990 {
      flex-flow: column wrap;
      height: auto;

      > .ant-col {
        flex-basis: 100%;
      }
    }

    > .ant-col {
      flex-basis: 50%;
      flex-direction: column;
      justify-content: center;
      width: 100%;

      &:first-child {
        align-items: center;
        background: @2column-bg !important;
        display: flex;
        justify-content: center;
        padding: 4rem !important;

        @media @max480 {
          padding: 3rem 2rem !important;
        }
      }

      &:last-child {
        background: @white;
        display: flex;
        margin-bottom: 0;
        margin-top: 0;
        padding: 4rem !important;

        @media @min1200 {
          margin: auto;
          max-width: 40rem;
        }

        @media @max768 {
          padding: 4rem 2rem !important;
        }
      }
    }
  }
}
