@import "../_brand-colors";
@import "../_default-variables";

.filter-style() {
  background: @grey-light;
  margin-bottom: 3.4rem;
  padding: 1.3rem 2rem;
  width: 100%;

  input,
  select {
    width: 100%;
  }

  .ant-form-item-explain {
    padding-bottom: 0 !important;
  }

  > .ant-row {
    align-items: stretch;
    display: flex;

    @media screen and (width: 768px) {
      margin-bottom: -12px;
    }

    @media screen and (max-width: 767px) {
      padding-top: 12px; //gutter amount
    }

    > .ant-col {
      > .ant-form-item {
        margin: 0 !important;
      }

      &:last-child {
        .ant-btn {
          height: 3rem !important;
          min-width: unset;
          width: 100%;
        }

        @media screen and (max-width: 767px) {
          padding-bottom: 0 !important;

          .ant-btn {
            width: unset;
          }
        }
      }
    }
  }

  ~ .ant-row {
    margin-bottom: 3.4rem;
  }
}
