@import "../_default-variables";

.narrow-container() {
  margin-left: auto !important;
  margin-right: auto !important;
  padding-left: @gutter / 2 !important;
  padding-right: @gutter / 2 !important;
  width: 100%;

  @media @min769 {
    max-width: 95%;
  }

  @media @min991 {
    max-width: 60% !important;
  }

  @media @min1200 {
    max-width: 50% !important;
  }
}
