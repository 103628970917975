// AU Brand Colors
@yellow: #eab541;
@teal: #008DA9;
@aqua: #59c7db;
@light-plum: #513458;
@dark-plum: #27002D;

@primary: @yellow;
@secondary: @teal;
@tertiary: @light-plum;
@tertiary-dark: @dark-plum;
@quaternary: @aqua;

@alert: #FFF0B6;
@alert-bg: #fffbe6;
@alert-icon: #faad14;
@danger: #E81748;
@warning: @yellow;
@success: #52c41a;

@white: #ffffff;
@grey1: #5d5d5d;
@grey2: #a4a4a4;
@grey3: #e0e0e0;
@grey4: #f3f2f4;

@grey-dark: @grey1;
@grey-base: @grey2;
@grey-light: @grey3;
@grey-white: @grey4;

@heading-text-color: @tertiary-dark;
@text-color: @grey-dark;
@text-lighter-color: @grey-base;

@placeholder-color: @grey-dark;
@link-color: @quaternary;
@link-color-hover: #099ABD;

@step-icon-active: @tertiary-dark;
@2column-bg: @tertiary-dark;
@border-color: @grey-light;
@border-dark-color: @grey-base;
@icon-bg-color: @grey-light;
@grey-bg-color: @grey-white;
@grey-bg-darker-color: @grey-light;

@tab-border: @tertiary;
@tag-incomplete: @primary;
@tag-complete: @tertiary;
@tag-dashboard-incomplete: @grey-base;
@tag-dashboard-complete: @secondary;
@icon-status-active: @tertiary-dark;
@icon-status-default: @grey-base;

@menu-color: @heading-text-color;
@menu-active-color: @tertiary;

@loan-state-available: linear-gradient(135deg, lighten(@tertiary-dark, 10) 0%, @tertiary 100%);
@loan-state-live: linear-gradient(135deg, lighten(@primary, 10) 0%, @primary 100%);
@loan-state-inactive: linear-gradient(135deg, #a2A8ad 0%, #747e87 100%);
@loan-state-pending: linear-gradient(135deg, lighten(@secondary, 10) 0%, @secondary 100%);
@loan-state-warning: linear-gradient(135deg, #f99840 0%, #e87734 100%);
