@import "../../../Less/_antd-overrides";
@import "../../../Less/_brand-colors";
@import "../../../Less/_typography";
@import "../../../Less/_default-variables";
@import "../../../Less/Mixins/_box-shadow-style";
@import "../../../Less/Mixins/_faicon";

.theme-step {
  flex: 1 1 auto !important;

  @media @max768 {
    margin-bottom: 0.5rem !important;
  }

  .ant-steps-default & {
    margin: 0;
    overflow: inherit;
    padding: 0 !important;
    vertical-align: middle !important;
    white-space: pre-wrap !important;

    &:last-child {
      padding-right: 0 !important;
    }

    .ant-steps-item-container {
      align-items: center;
      display: flex;
      height: 100%;

      .ant-steps-item-tail {
        display: none !important;
      }

      .ant-steps-item-icon {
        flex: 0 0 @step-icon;
      }

      .ant-steps-item-content {
        display: flex;
        min-height: auto !important;

        @media @min769 {
          flex-direction: column;
        }
      }
    }

    .ant-steps-item-title {
      color: @link-color !important;
      font-family: @step-title-font !important;
      font-size: @font-size-step-title !important;
      line-height: 1.2 !important;

      .ant-steps-item-subtitle {
        margin: 0;
      }

      &::after {
        display: none !important;
      }
    }

    .ant-steps-item-icon {
      align-items: center;
      background-color: transparent !important;
      border: none !important;
      display: flex;

      &::before {
        .faicon(@fa-var-dot-circle, far);

        color: @text-lighter-color !important;
        font-size: @step-icon;

        @media @max990 {
          font-size: 1.8rem;
        }

        .ant-steps-item-finish & {
          .faicon(@fa-var-check-circle, far);
        }
      }

      .ant-steps-icon {
        display: none !important;
      }
    }

    &.ant-steps-item-finish {
      .ant-steps-item-icon {
        &::before {
          .faicon(@fa-var-check-circle, fas) !important;

          color: @step-icon-active !important;
        }
      }
    }

    &.ant-steps-item-active {
      .ant-steps-item-icon {
        &::before {
          .faicon(@fa-var-dot-circle, fas);

          color: @step-icon-active !important;
        }
      }
    }

    &.ant-steps-item-disabled {
      filter: none !important;

      .ant-steps-item-container {
        background: none !important;
      }

      .ant-steps-item-content {

        .ant-steps-item-description {
          line-height: 1.2;
          max-width: unset !important;
          padding-bottom: 0;
          padding-top: 0.5rem;
        }

        @media @max768 {
          display: flex;
          flex-flow: column wrap;
        }
      }

      .ant-steps-item-title {
        font-family: @step-title-font;
      }

      .ant-steps-item-description {
        color: @text-color !important;
        font-family: @text-font;
      }

      &.ant-stpes-item-active {
        .ant-steps-item-title {
          color: @text-color !important;
        }
      }
    }

    &.ant-steps-item-error {
      .ant-steps-item-container {

        .ant-steps-item-icon {
          &::before {
            .faicon(@fa-var-times-circle, far);

            color: @step-icon-active !important;
          }
        }
      }
    }
  }
}

@primary-color: #012a64;@primary: #012a64;@secondary: #056e47;@tertiary: #056e47;@tertiary-dark: #056e47;@quaternary: #24B3D5;@grey-light: #E3E3E3;@grey-white: #F4F4F4;@alert: #FDE6F1;@menu-color: #056e47;@menu-active-color: #033d28;@heading-text-color: #012a64;@text-color: #5d5d5d;@input-placeholder-color: #5d5d5d;@link-color: #24B3D5;@link-hover-color: #44C0D6;@2column-bg: #012a64;@step-icon-active: #012a64;@border-color: #E3E3E3;@icon-bg-color: #E3E3E3;@grey-bg-color: #F4F4F4;@grey-bg-darker-color: #E3E3E3;@heading-1-size: 2.375rem;@heading-2-size: 2.375rem;@heading-3-size: 1.8rem;@heading-4-size: 1.5rem;@heading-5-size: 1.3rem;@font-size-stand: 1.0625rem;@font-size-md: 1.125rem;@font-size-sm: 0.875rem;@font-size-xs: 0.875rem;@font-size-xxs: 0.75rem;@font-size-label: 0.875rem;@font-size-button: 1.0625rem;@font-size-step-title: 1.25rem;@font-size-input: 0.875rem;@heading-font: 'Avenir LT Black', sans-serif;@text-font: 'Avenir LT Light', sans-serif;@label-font: 'Avenir LT Black', sans-serif;@step-title-font: 'Avenir LT Black', sans-serif;@button-font: 'Avenir LT Black', sans-serif;@small-font: 'Avenir LT Light', sans-serif;@menu-font: 'Avenir LT Light', sans-serif;@badge-line-height-0: 2.4;@badge-line-height: 2rem;