@import "../../Less/Mixins/_bg-2column-style";
@import "../../Less/Mixins/_text-style";
@import "../../Less/Mixins/_box-shadow-style";
@import "../../Less/_brand-colors";

.theme-signup {
  .bg-2column-style();

  .theme-contents-wrapper {
    text-align: center;

    > .theme-link,
    .theme-title {
      display: block;
      margin-bottom: @section-gap;
    }

    .theme-steps {
      margin-left: 0 !important;
      margin-top: 2rem;
    }

    .theme-step {
      .box-shadow(clippy);

      margin: 0 !important;

      @media @min769 {
        margin: 0 -0.4rem;
      }

      &:not(:first-child) {
        padding-left: 0 !important;
      }

      &.ant-steps-item-active {
        &::before {
          display: none;
        }
      }

      &::after {
        display: none !important;
      }

      .ant-steps-item-container {
        align-items: center;
        display: flex;
        flex-direction: column;
        margin: 0;
        text-align: center;

        .ant-steps-item-icon {
          background: @primary;
          border: 0;
          height: 1.875rem;
          margin-right: 0;
          position: absolute;
          top: 0;
          transform: translateY(-50%);
          width: 1.875rem;
          z-index: 3;

          .ant-steps-icon {
            color: @white;
            font-size: @font-size-xs;
            line-height: 1.875rem;
          }
        }

        .ant-steps-item-content {
          background: #fff;
          border: 1px solid #e0e0e0;
          height: 100%;
          padding: 1rem 1rem 2rem !important;
          width: 100%;
          @angle: 1rem;

          @media @min991 {
            clip-path: polygon(@angle 0%, 100% 0%, ~"calc(100% - @{angle})" 100%, 0% 100%);
          }

          .ant-steps-item-title {
            .text-style(heading);

            color: @text-color !important;
            font-size: @font-size-step-title !important;
            margin: 1rem 0;
            white-space: pre-wrap;
            word-break: break-word;
          }

          .ant-steps-item-description {
            .text-style(body);

            font-size: @font-size-xs !important;
            margin: auto;
          }
        }
      }
    }
  }
}

@primary-color: #012a64;@primary: #012a64;@secondary: #056e47;@tertiary: #056e47;@tertiary-dark: #056e47;@quaternary: #24B3D5;@grey-light: #E3E3E3;@grey-white: #F4F4F4;@alert: #FDE6F1;@menu-color: #056e47;@menu-active-color: #033d28;@heading-text-color: #012a64;@text-color: #5d5d5d;@input-placeholder-color: #5d5d5d;@link-color: #24B3D5;@link-hover-color: #44C0D6;@2column-bg: #012a64;@step-icon-active: #012a64;@border-color: #E3E3E3;@icon-bg-color: #E3E3E3;@grey-bg-color: #F4F4F4;@grey-bg-darker-color: #E3E3E3;@heading-1-size: 2.375rem;@heading-2-size: 2.375rem;@heading-3-size: 1.8rem;@heading-4-size: 1.5rem;@heading-5-size: 1.3rem;@font-size-stand: 1.0625rem;@font-size-md: 1.125rem;@font-size-sm: 0.875rem;@font-size-xs: 0.875rem;@font-size-xxs: 0.75rem;@font-size-label: 0.875rem;@font-size-button: 1.0625rem;@font-size-step-title: 1.25rem;@font-size-input: 0.875rem;@heading-font: 'Avenir LT Black', sans-serif;@text-font: 'Avenir LT Light', sans-serif;@label-font: 'Avenir LT Black', sans-serif;@step-title-font: 'Avenir LT Black', sans-serif;@button-font: 'Avenir LT Black', sans-serif;@small-font: 'Avenir LT Light', sans-serif;@menu-font: 'Avenir LT Light', sans-serif;@badge-line-height-0: 2.4;@badge-line-height: 2rem;