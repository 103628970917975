@import "../../Less/_brand-colors";
@import "../../Less/_typography";
@import "../../Less/_default-variables";

.text-style(@font-style) when (@font-style = body) {
  color: @text-color;
  font-family: @text-font !important;
  font-size: @font-size-stand !important;
  text-transform: inherit !important;
}

.text-style(@font-style) when (@font-style = heading) {
  color: @heading-text-color !important;
  font-family: @heading-font !important;
  font-weight: 500 !important;
}

.text-style(@font-style) when (@font-style = menu) {
  color: @menu-color;
  font-family: @menu-font;
  font-size: @font-size-stand !important;
}

.text-style(@font-style) when (@font-style = submenu) {
  color: @white;
  font-family: @menu-font;
  font-size: @font-size-stand !important;
}

.text-style(@font-style) when (@font-style = label) {
  color: @text-color !important;
  font-family: @label-font !important;
  font-size: @font-size-label !important;
  margin-bottom: 0 !important;
  text-transform: initial !important;
}

.text-style(@font-style) when (@font-style = md-black) {
  color: @text-color !important;
  font-family: @heading-font !important;
  font-size: @font-size-md !important;
}

.text-style(@font-style) when (@font-style = small) {
  color: @text-color !important;
  font-family: @small-font !important;
  font-size: @font-size-sm !important;
}
