@import "../_default-variables";
@import "../_brand-colors";

.investor-match() {
  .title-wrapper {
    background: @tertiary-dark;
    padding: @gap-base;

    * {
      color: @white !important;
    }

    .theme-title {
      margin-bottom: 0;
      margin-right: @gap-sm;
    }

    .theme-badge {
      background: @primary;
      line-height: 1.2;
      min-height: 1.5rem;
      min-width: 1.5rem !important;
      padding: 0.3rem !important;
      text-align: center;
    }
  }

  .list-wrapper {
    margin-top: @gap-base;
  }
}
