/* Some components are still old.. not using atom but it has own markup */
@import "./_default-variables";
@import "./_typography";
@import "./_brand-colors";

.theme-filter {
  .filter-style();
}

.tabs-container {
  margin-bottom: @gap-medium;
  padding-inline-start: 0;

  .tab {
    display: inline-flex;

    &:not(:last-child) {
      margin-right: @gap-base;
    }

    button:not(.ant-ctn-icon-only),
    a:link,
    a:visited {
      background: @white;
      border: none;
      color: @heading-text-color;
      display: block;
      font-family: @heading-font;
      font-size: @font-size-stand;
      padding: 8px 16px;
      position: relative;
      text-transform: uppercase;

      &:after {
        background: @tab-border;
        border-radius: 0 0.5rem 0 0;
        bottom: 0;
        content: "";
        height: 0.2rem;
        left: 0;
        position: absolute;
        width: 100%;
      }

      &.active {
        background: @secondary;
        border-radius: 0 1rem 0 0;
        color: @white;

        &:after {
          display: none;
        }
      }
    }
  }

  @media @max480 {
    align-self: stretch;
    flex: auto;
    flex-direction: column;
    margin-right: 1rem;
    overflow: hidden;
    padding-inline-start: 0;
    white-space: nowrap;

    .tab {
      display: block;
      margin-right: 0 !important;

      button,
      a {
        text-align: left;
        width: 100%;
      }
    }
  }
}

.form-type-select,
.form-type-search {
  position: relative;

  select,
  input {
    background: @grey-bg-color !important;
    border: none !important;
    border-radius: @border-radius-base !important;
    color: @text-color !important;
    font-family: @text-font !important;
    font-size: @font-size-input !important;
    font-weight: @font-weight-normal !important;
    height: @form-height;
    line-height: 1.5 !important;
    padding: 0.7rem !important;
    /* stylelint-disable */
    -webkit-appearance: none;
    -moz-appearance: none;
    -o-appearance: none;
    appearance: none;

    &::placeholder,
    &::-moz-placeholder,
    &:-moz-placeholder,
    &::-webkit-input-placeholder,
    &:-ms-input-placeholder {
      color: @placeholder-color !important;
      font-family: @text-font !important;
    }
    /* stylelint-enable */

    &:focus,
    &:focus-within,
    &:focus-visible,
    &:active,
    &:hover {
      box-shadow: none !important;
      outline: 0 !important;
    }
  }
  &::after {
    bottom: 0;
    content: "\f078";
    font-family: @fontawesome-font;
    font-size: @font-size-input;
    font-weight: @fontawesome-weight;
    pointer-events: none;
    position: absolute;
    right: @gap-base;
    transform: translateY(-50%);
  }
}
