@import "../../Less/_antd-overrides";
@import "../../Less/_brand-colors";
@import "../../Less/_typography";

.input-style() {
  background: @grey-bg-color !important;
  border: none !important;
  border-radius: @border-radius-base !important;
  color: @text-color !important;
  font-family: @text-font !important;
  font-size: @font-size-input !important;
  font-weight: @font-weight-normal !important;
  height: @form-height;
  line-height: 1.5 !important;
  padding: 0.7rem !important;
  /* stylelint-disable */
  -webkit-appearance: none;
  -moz-appearance: none;
  -o-appearance: none;
  appearance: none;

  &::placeholder,
  &::-moz-placeholder,
  &:-moz-placeholder,
  &::-webkit-input-placeholder,
  &:-ms-input-placeholder {
    color: @placeholder-color !important;
    font-family: @text-font !important;
  }
/* stylelint-enable */

  &:focus,
  &:focus-within,
  &:focus-visible,
  &:active,
  &:hover {
    box-shadow: none !important;
    outline: 0 !important;
  }
}

.radio-checkbox-label-style {
  color: @text-color !important;
  font-family: @text-font !important;
}
