@import "./_make-col";
@import "../../Less/_default-variables";

.input-group-style (@columns:4) {
  @media @min769 {
    .theme-form-item {
      .make-col(@columns);
    }
  }
}
