.box-shadow(@shadow) when (@shadow = focus) {
  box-shadow: 0 0 0 0.2rem rgba(0, 141, 169, 0.15) !important;
}

.box-shadow(@shadow) when (@shadow = float) {
  box-shadow: 0 0.35rem 1rem -0.2rem rgba(0, 0, 0, 0.1), 0.2rem 0.2rem 0.35rem 0.2rem rgba(0, 0, 0, 0.1);
}

.box-shadow(@shadow) when (@shadow = clippy) {
  filter: drop-shadow(0.3rem 0.3rem 0.3rem rgba(0, 0, 0, 0.2));
}

.box-shadow(@shadow) when (@shadow = default) {
  box-shadow: 0 0.2rem 0.3rem -0.1rem rgba(0, 0, 0, 0.3);
}
