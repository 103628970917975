@import "../../../Less/_default-variables";
@import "../../../Less/Mixins/_text-style";
@import "../../../Less/Mixins/_input-warning-highlight.less";
@import "../../../Less/Mixins/_input-style.less";

.theme-form-item,
.ant-form-item {
  align-items: flex-start;
  display: flex;
  flex-flow: column wrap !important;
  justify-content: flex-start;
  margin: @formItem-m 0 !important;

  > .ant-form-item-row {
    align-items: flex-start;
    flex-flow: column wrap;
    width: 100%;
  }

  .ant-form-item-control {
    flex: 1 !important;
    width: 100%;

    .theme-typography-text {
      &.ant-typography {
        display: inline-block;
        margin-bottom: @gap-medium;
      }
    }

    .theme-input > .ant-input {
      height: auto;
      padding: 0 !important;
    }
  }

  @media @max768 {
    margin: @formItem-m-mobile 0 !important;
  }

  .theme-form-item {
    margin-bottom: 0 !important;
  }

  .ant-form-item-label {
    flex: 0 0 auto !important;
    padding: 0 0 0.5rem !important;

    > label::after {
      content: "";
    }

    label {
      display: inline;
      .text-style(label);

      &.ant-form-item-required {
        &::before {
          display: none !important;
        }

        &::after {
          content: "*";
          display: inline-block;
        }
      }
    }
  }

  &.theme-form-row-style {
    margin-bottom: @gap-big !important;
    margin-top: @gap-big !important;
  }

  &.theme-form-label-body-style {
    .ant-form-item-label,
    label {
      .text-style(body);
    }
  }

  &.theme-form-item-type-dragger {
    margin-top: 1rem !important;

    @media @max768 {
      margin-top: 0 !important;
    }
  }

  &.theme-no-label-form-item {

    .ant-form-item-label {
      visibility: hidden;
    }

    @media @max768 {
      margin-top: 0 !important;
      .ant-form-item-label {
        display: none;
      }
    }
  }

  &.theme-form-item-wrapper {
    margin: 0 !important;
  }

  &.ant-form-item-has-error {
    .ant-form-item-explain {
      color: @danger !important;
      font-family: @text-font !important;
      padding: 0.3rem 0 @gap-base 0;
    }
  }

  &.ant-form-item-has-warning {
    .ant-form-item-control {
      .input-warning-highlight;
    }

    &.theme-form-items-wrapper {
      > .ant-form-item-row > .ant-form-item-control {
        border: none;
      }
    }
  }

  &.ant-form-item-hidden {
    display: none;
  }

  &.theme-form-items-wrapper {
    width: 100%;

    & + .theme-form-item {
      margin-top: 0 !important;
    }

    @media @max480 {
      margin-bottom: 0 !important;
    }
  }

  // has input-group-addon as a child
  &.theme-form-amount {
    .ant-form-item-control-input-content {
      display: flex;
      position: relative;

      .input-group-addon {
        background: transparent;
        border: none;
        left: 0.7rem;
        padding: 0;
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        .text-style(body);
      }

      > input {
        .input-style();

        border: 0;
        padding-left: 1.5rem !important;
        width: 100%;
      }
    }
  }
}

@primary-color: #012a64;@primary: #012a64;@secondary: #056e47;@tertiary: #056e47;@tertiary-dark: #056e47;@quaternary: #24B3D5;@grey-light: #E3E3E3;@grey-white: #F4F4F4;@alert: #FDE6F1;@menu-color: #056e47;@menu-active-color: #033d28;@heading-text-color: #012a64;@text-color: #5d5d5d;@input-placeholder-color: #5d5d5d;@link-color: #24B3D5;@link-hover-color: #44C0D6;@2column-bg: #012a64;@step-icon-active: #012a64;@border-color: #E3E3E3;@icon-bg-color: #E3E3E3;@grey-bg-color: #F4F4F4;@grey-bg-darker-color: #E3E3E3;@heading-1-size: 2.375rem;@heading-2-size: 2.375rem;@heading-3-size: 1.8rem;@heading-4-size: 1.5rem;@heading-5-size: 1.3rem;@font-size-stand: 1.0625rem;@font-size-md: 1.125rem;@font-size-sm: 0.875rem;@font-size-xs: 0.875rem;@font-size-xxs: 0.75rem;@font-size-label: 0.875rem;@font-size-button: 1.0625rem;@font-size-step-title: 1.25rem;@font-size-input: 0.875rem;@heading-font: 'Avenir LT Black', sans-serif;@text-font: 'Avenir LT Light', sans-serif;@label-font: 'Avenir LT Black', sans-serif;@step-title-font: 'Avenir LT Black', sans-serif;@button-font: 'Avenir LT Black', sans-serif;@small-font: 'Avenir LT Light', sans-serif;@menu-font: 'Avenir LT Light', sans-serif;@badge-line-height-0: 2.4;@badge-line-height: 2rem;