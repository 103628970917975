@import "../../Less/_brand-colors";

.input-warning-highlight {
  border-color: @warning;
  border-style: solid;
  border-width: 2px;
}

.clear-input-warning-highlight {
  border-color: transparent;
  border-style: none;
}
