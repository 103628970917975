@import "../../../Less/Mixins/_text-style";
@import "../../../Less/Mixins/_faicon";

.theme-menu {
  .text-style(menu);

  line-height: 3rem;

  > .ant-menu-item,
  > .ant-menu-submenu {
    line-height: inherit;
    margin-top: 0;
    .text-style(menu);

    &::after {
      transition: all 0.3s ease-in-out;
    }

    &:hover,
    &.ant-menu-item-selected,
    &.ant-menu-submenu-active {
      color: @menu-color;
    }
  }
  > .ant-menu-submenu {
    .ant-menu-submenu-title {
      &::after {
        color: @icon-status-default;
        padding-left: 0.5rem;
        transition: all 0.2s ease-in-out;
        .faicon(@fa-var-chevron-circle-down, fas);
      }
    }
  }

  &.ant-menu-dark {
    background: @menu-color !important;

    .ant-menu-item {

      > span > a {
        color: @white !important;
        font-size: @font-size-stand;
      }
    }
  }
  // mobile menu
  &.ant-menu-dark.ant-menu-inline {

    .ant-menu-item {
      &:not(:last-child) {
        margin: 0;
      }

      &.ant-menu-item-selected {
        background: @menu-active-color !important;

        .ant-menu-item {
          color: @menu-color !important;
        }
      }
    }

    > .ant-menu-item,
    > .ant-menu-submenu-inline {
      padding: 0 2em !important;
      .text-style(submenu);
    }

    > .ant-menu-item {
      height: 2.5em;
      line-height: 2.5em;

      .ant-menu-title-content {
        padding: 0.5em !important;
      }
    }

    > .ant-menu-submenu-inline {
      > .ant-menu-sub.ant-menu-inline {
        background: @grey-bg-color;
        margin-left: -2.5em;
        margin-right: -2.5em;
        width: calc(100% + 5em);

        .ant-menu-item {
          margin: 0;
          &:not(:last-child) {
            border-bottom: 1px dashed @grey2;
          }
          &:not(.ant-menu-item-selected) {
            a {
              color: @menu-color !important;
            }
          }
        }
      }

      > .ant-menu-submenu-title {
        height: 2.5em;
        line-height: 2.5em;
        margin: 0;
        padding: 0 !important;
      }

      .ant-menu-title-content {
        padding: 0 0.5em !important;
      }

      .ant-menu-submenu-arrow {
        visibility: hidden;
      }

      &.ant-menu-submenu-open {
        .ant-menu-submenu-title {

          &::after {
            color: @white;
            transform: rotate(180deg);
          }
        }
      }
    }
  }
}

.ant-menu-submenu-popup {

  > ul.ant-menu-vertical {
    border-radius: 0;

    > li {
      background: @menu-color;
      height: 3rem;
      line-height: 3rem;
      margin: 0 !important;

      &:hover,
      &.ant-menu-item-active,
      &.ant-menu-item-selected {
        background: @menu-active-color;
        color: @white;
      }

      .ant-menu-title-content,
      a {
        color: @white !important;
        .text-style(submenu);
      }
    }
  }
}

@primary-color: #012a64;@primary: #012a64;@secondary: #056e47;@tertiary: #056e47;@tertiary-dark: #056e47;@quaternary: #24B3D5;@grey-light: #E3E3E3;@grey-white: #F4F4F4;@alert: #FDE6F1;@menu-color: #056e47;@menu-active-color: #033d28;@heading-text-color: #012a64;@text-color: #5d5d5d;@input-placeholder-color: #5d5d5d;@link-color: #24B3D5;@link-hover-color: #44C0D6;@2column-bg: #012a64;@step-icon-active: #012a64;@border-color: #E3E3E3;@icon-bg-color: #E3E3E3;@grey-bg-color: #F4F4F4;@grey-bg-darker-color: #E3E3E3;@heading-1-size: 2.375rem;@heading-2-size: 2.375rem;@heading-3-size: 1.8rem;@heading-4-size: 1.5rem;@heading-5-size: 1.3rem;@font-size-stand: 1.0625rem;@font-size-md: 1.125rem;@font-size-sm: 0.875rem;@font-size-xs: 0.875rem;@font-size-xxs: 0.75rem;@font-size-label: 0.875rem;@font-size-button: 1.0625rem;@font-size-step-title: 1.25rem;@font-size-input: 0.875rem;@heading-font: 'Avenir LT Black', sans-serif;@text-font: 'Avenir LT Light', sans-serif;@label-font: 'Avenir LT Black', sans-serif;@step-title-font: 'Avenir LT Black', sans-serif;@button-font: 'Avenir LT Black', sans-serif;@small-font: 'Avenir LT Light', sans-serif;@menu-font: 'Avenir LT Light', sans-serif;@badge-line-height-0: 2.4;@badge-line-height: 2rem;