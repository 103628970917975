@import "../_default-variables";

.vertical-page-inside-form-style() {
  .theme-form-item {
    &:first-child {
      margin-top: @gap-big !important;
    }
  }

  .theme-button {
    margin-bottom: @gap-big !important;
    margin-top: @gap-big !important;
    width: 100%;
  }
}
