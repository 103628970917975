@import "../_brand-colors";
@import "../_typography";
@import "../Mixins/_faicon";

.action-icon-button-style() {
  background: @icon-bg-color !important;
  border-radius: 50%;
  height: @action-icon-size;
  margin-right: 0;
  padding: 0.5rem;
  right: 0;
  width: @action-icon-size;

  .anticon {
    align-items: center;
    color: @text-color !important;
    display: flex;
    font-size: @font-size-md !important;
    justify-content: center;

    svg {
      display: none;
    }
  }
}
