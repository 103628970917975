@import "../../Less/_antd-overrides";
@import "../../Less/_brand-colors";
@import "../../Less/_typography";

.button-style() {
  align-items: center;
  border-radius: @border-radius-base !important;
  border-style: solid;
  border-width: 0.15rem;
  box-shadow: none !important;
  box-sizing: border-box !important;
  display: inline-flex !important;
  font-family: @button-font !important;
  font-size: @font-size-button !important;
  height: auto !important;
  justify-content: center;
  line-height: 1.6;
  min-width: 7rem;
  padding: 0.7rem 1rem !important;
  text-align: center !important;
  text-decoration: none !important;
  text-shadow: none !important;
}

.button-color-style(@btn-color: @primary-color ) {
  .button-style();

  background-color: @btn-color !important;
  border-color: @btn-color !important;
  color: @white !important;

  &:hover,
  &:active,
  &:focus,
  &:focus-within,
  &:focus-visible,
  &:target,
  &:active:focus {
    box-shadow: inset 0 0 0 0 transparent;
    opacity: 0.75 !important;
    text-decoration: none;
  }

  &:disabled {
    opacity: 0.6 !important;

    &:hover,
    &:active,
    &:focus,
    &:focus-within,
    &:focus-visible,
    &:target,
    &:active:focus {
      opacity: 0.6 !important;
    }
  }
}

.button-ghost-style(@btn-color: @primary-color ) {
  .button-style();

  background: transparent !important;
  border-color: @btn-color !important;
  color: @btn-color !important;

  &:hover,
  &:active,
  &:focus,
  &:focus-within,
  &:focus-visible,
  &:target,
  &:active:focus {
    box-shadow: inset 0 0 0 0 transparent;
    opacity: 0.75 !important;
    text-decoration: none;
  }
}
