@import "../../../Less/Mixins/_text-style";
@import "../../../Less/_default-variables";
@import "../../../Less/Mixins/_action-icon-button-style";
@import "../../../Less/Mixins/_faicon";

.theme-dragger {
  background: white !important;
  border: 1px dashed @border-dark-color !important;

  .ant-upload-btn {
    padding: 3rem 0 !important;
  }

  .anticon {
    color: @text-lighter-color !important;
  }

  .ant-upload-drag-container {
    padding: 0 1rem;
  }

  .ant-upload-text {
    .text-style(small);

    word-break: break-word;
  }

  .ant-upload-hint {
    .text-style(small);

    color: @text-lighter-color !important;
    word-break: break-word;
  }

  & + .ant-upload-list:not(:empty) {
    margin-bottom: @section-gap;
    margin-top: @formItem-m;

    .ant-upload-list-item {
      height: auto !important;

      .ant-upload-list-item-info {
        background: @grey-bg-color !important;
        padding: 0 !important;
      }

      .ant-upload-span {
        padding: 0.7rem 1rem;
      }

      .ant-upload-list-item-name {
        display: block;
        height: 100%;
        margin-top: 0 !important;
        padding: 0 0.5rem;
        .text-style(md-black);
      }

      .anticon-paper-clip {
        &::before {
          .faicon(@fa-var-file-alt, far);

          color: @text-lighter-color !important;
          font-size: 1.5rem;
        }

        svg {
          display: none;
        }
      }

      .ant-upload-list-item-card-actions {
        .action-icon-button-style();

        padding: 0;
        .ant-upload-list-item-card-actions-btn {
          height: @action-icon-size;
          opacity: 1;
          width: @action-icon-size;
        }

        .anticon {
          &::before {
            .faicon(@fa-var-trash-alt, far);
          }
        }
      }
    }
  }
}

@primary-color: #012a64;@primary: #012a64;@secondary: #056e47;@tertiary: #056e47;@tertiary-dark: #056e47;@quaternary: #24B3D5;@grey-light: #E3E3E3;@grey-white: #F4F4F4;@alert: #FDE6F1;@menu-color: #056e47;@menu-active-color: #033d28;@heading-text-color: #012a64;@text-color: #5d5d5d;@input-placeholder-color: #5d5d5d;@link-color: #24B3D5;@link-hover-color: #44C0D6;@2column-bg: #012a64;@step-icon-active: #012a64;@border-color: #E3E3E3;@icon-bg-color: #E3E3E3;@grey-bg-color: #F4F4F4;@grey-bg-darker-color: #E3E3E3;@heading-1-size: 2.375rem;@heading-2-size: 2.375rem;@heading-3-size: 1.8rem;@heading-4-size: 1.5rem;@heading-5-size: 1.3rem;@font-size-stand: 1.0625rem;@font-size-md: 1.125rem;@font-size-sm: 0.875rem;@font-size-xs: 0.875rem;@font-size-xxs: 0.75rem;@font-size-label: 0.875rem;@font-size-button: 1.0625rem;@font-size-step-title: 1.25rem;@font-size-input: 0.875rem;@heading-font: 'Avenir LT Black', sans-serif;@text-font: 'Avenir LT Light', sans-serif;@label-font: 'Avenir LT Black', sans-serif;@step-title-font: 'Avenir LT Black', sans-serif;@button-font: 'Avenir LT Black', sans-serif;@small-font: 'Avenir LT Light', sans-serif;@menu-font: 'Avenir LT Light', sans-serif;@badge-line-height-0: 2.4;@badge-line-height: 2rem;