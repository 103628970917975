@import "../../../Less/Mixins/_text-style";
@import "../../../Less/Mixins/_transition";
@import "../../../Less/Mixins/_box-shadow-style";
@import "../../../Less/Mixins/_alignfull";
@import "../../../Less/Mixins/_container";

.theme-top-menus {
  .alignfull();

  background: @menu-color;

  > .theme-top-menus-contents {
    .container();

    .ant-image-img {
      max-height: 4rem;
      max-width: 100%;
      transition: all 1s ease-in-out;
      width: auto;
    }
  }

  .ant-col {
    align-items: center;

    .ant-image {
      align-items: center;
      display: flex;
      height: 100%;
      min-height: 4rem;
    }
  }
  .theme-menu {
    justify-content: flex-end;

    &.ant-menu-horizontal > .ant-menu-item,
    &.ant-menu-horizontal > .ant-menu-submenu {
      color: @white;
      padding-bottom: 0.5rem;
      padding-top: 0.5rem;

      &:hover,
      &.ant-menu-item-selected,
      &.ant-menu-submenu-active {
        background: @menu-active-color !important;
      }

      .ant-menu-submenu-title::after {
        color: rgba(255, 255, 255, 0.4);
      }
    }
  }
}

.ant-menu-submenu-popup.ant-menu-dark {
  .ant-menu-sub {
    background: @menu-color;
    left: -1.5em;
    position: relative;
  }
}

@primary-color: #012a64;@primary: #012a64;@secondary: #056e47;@tertiary: #056e47;@tertiary-dark: #056e47;@quaternary: #24B3D5;@grey-light: #E3E3E3;@grey-white: #F4F4F4;@alert: #FDE6F1;@menu-color: #056e47;@menu-active-color: #033d28;@heading-text-color: #012a64;@text-color: #5d5d5d;@input-placeholder-color: #5d5d5d;@link-color: #24B3D5;@link-hover-color: #44C0D6;@2column-bg: #012a64;@step-icon-active: #012a64;@border-color: #E3E3E3;@icon-bg-color: #E3E3E3;@grey-bg-color: #F4F4F4;@grey-bg-darker-color: #E3E3E3;@heading-1-size: 2.375rem;@heading-2-size: 2.375rem;@heading-3-size: 1.8rem;@heading-4-size: 1.5rem;@heading-5-size: 1.3rem;@font-size-stand: 1.0625rem;@font-size-md: 1.125rem;@font-size-sm: 0.875rem;@font-size-xs: 0.875rem;@font-size-xxs: 0.75rem;@font-size-label: 0.875rem;@font-size-button: 1.0625rem;@font-size-step-title: 1.25rem;@font-size-input: 0.875rem;@heading-font: 'Avenir LT Black', sans-serif;@text-font: 'Avenir LT Light', sans-serif;@label-font: 'Avenir LT Black', sans-serif;@step-title-font: 'Avenir LT Black', sans-serif;@button-font: 'Avenir LT Black', sans-serif;@small-font: 'Avenir LT Light', sans-serif;@menu-font: 'Avenir LT Light', sans-serif;@badge-line-height-0: 2.4;@badge-line-height: 2rem;