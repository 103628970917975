@import "../Less/_font-face.css";
@import "../Less/Mixins/_rfs";
@import "../Less/Mixins/_text-style";
// Set the base rem
html {
  font-size: 16px !important;
}

@font-size-default: 16px;

@light-font: "Avenir Next LT Regular", sans-serif;
@medium-font: "Avenir Next LT Medium", sans-serif;
@heavy-font: "Avenir Next LT Demi Bold", sans-serif;
@black-font: "Avenir Next LT Regular Bold", sans-serif;
@fontawesome-font: "Font Awesome 5 Free";

@heading-font: @heavy-font;
@text-font: @medium-font;
@label-font: @heavy-font;
@step-title-font: @heavy-font;
@button-font: @black-font;
@small-font: @light-font;
@menu-font: @heavy-font;

@font-weight-light: 300;
@font-weight-normal: 400;
@font-weight-medium: 500;
@font-weight-bold: 600;
@font-weight-black: 700;
@fontawesome-weight: 900;

@heading-1-size: 2rem; //32
@heading-2-size: 2rem; //32
@heading-3-size: 1.625rem; //26
@heading-4-size: 1.5rem; //24
@heading-5-size: 1.25rem; //20

@font-size-stand: 1.0625rem; //17
@font-size-md: 1.5rem; //24
@font-size-sm: 1.03125rem; //16.5
@font-size-xs: 0.875rem; //14
@font-size-xxs: 0.75rem; //12

@font-size-label: @font-size-sm;
@font-size-button: @font-size-sm;
@font-size-overview-badge: @font-size-xs;
@font-size-badge: @font-size-xxs;
@font-size-step-title: @font-size-stand;
@font-size-input: @font-size-sm;
@font-size-big-icon: 4.5rem;

h1,
h2 {
  .rfs(@heading-1-size);
  .text-style(heading);
}

h3 {
  .rfs(@heading-3-size);
  .text-style(heading);

  font-family: @black-font !important;
}

h4 {
  .rfs(@heading-4-size);
  .text-style(heading);
}

h5 {
  .rfs(@heading-5-size);
  .text-style(heading);
}
