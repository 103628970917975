@import "./_clearfix";
@import "../../Less/_default-variables";

.make-col(@columns, @gutter: @grid-gutter-width, @breakPoint: @min769) {
  min-height: 1px;
  padding-left: (@gutter / 2);
  padding-right: (@gutter / 2);
  position: relative;
  width: 100% !important;

  @media @breakPoint {
    float: left;
    margin-bottom: 0;
    /* stylelint-disable-next-line */
    width: percentage((@columns / @grid-columns)) !important;
  }
}
