@import "../../../node_modules/@fortawesome/fontawesome-free/less/fontawesome";
@import "../../../node_modules/@fortawesome/fontawesome-free/less/solid";
@import "../../../node_modules/@fortawesome/fontawesome-free/less/brands";
@import "../../../node_modules/@fortawesome/fontawesome-free/less/regular";

.faicon (@icon, @type) when (@type = fas) {
  .fa-icon;
  .fas;

  content: @icon !important;
}

.faicon (@icon, @type) when (@type = far) {
  .fa-icon;
  .far;

  content: @icon !important;
}
