@import "../../../Less/_brand-colors";
@import "../../../Less/_default-variables";
@import "../../../Less/Mixins/_box-shadow-style";
@import "../../../Less/Mixins/_transition";

.theme-panel {
  display: block;
  margin-bottom: @gap-base;
  padding: @padding-panel;
  position: relative;
  transition: all 0.2s ease-in-out;

  .panel-icon {
    color: @grey-dark;
    position: absolute;
    right: 1rem;
    top: 50%;
    transform: translateY(-50%);
  }

  &:not(.noLink) {
    .panel-icon {
      padding-right: @gap-sm;
    }
    &:hover {
      .panel-icon {
        padding-right: 0;
      }
    }
  }

  &.border {
    border: 1px solid @border-dark-color;
    &:not(.noLink):not(.skeleton):hover {
      .box-shadow(default);

      transform: translateY(-0.2rem);
    }
  }

  &.background {
    background-color: @grey-bg-color;
    border: none;
    outline: none;
    overflow: hidden;
    padding: 2em 2em 1.6em 2em;
    position: relative;
    .box-shadow(default);

    &:hover {
      box-shadow: none;
    }
  }

  &.noPadding {
    padding: 0;
  }

  &.skeleton {
    background: @grey-bg-color !important;
    border: none !important;
    padding: @gap-sm !important;
  }

  .wrapper-has-icon {
    align-items: center;
    display: flex;
    justify-content: space-between;

    .panel-body {
      margin-right: auto;
    }
    .panel-icon {
      .transition();
      &:hover {
        opacity: 0.8;
      }
    }
  }

  .panel-body {
    .theme-title {
      margin-bottom: 0;
    }

    .returns-rate {
      margin-bottom: 0;
      margin-left: auto;
      margin-right: 1em;
    }
  }

  &.loan-panel {
    .panel-body {
      display: flex;
      width: 100%;
    }

    .group-thumbnail {
      align-items: center;
      background-color: @grey-bg-darker-color;
      display: flex;
      justify-content: center;
      min-height: 9.3rem;
      position: relative;
      width: 100%;

      svg {
        color: @text-color;
      }

      .theme-loan-state {
        left: 0;
        position: absolute;
        top: 0;
        transform: translateX(50%);
      }
    }

    .group-content {
      justify-content: space-between;
      padding: @padding-panel;

      .theme-badge-group {
        margin-bottom: @gap-sm;
      }

      .name {
        line-height: 1.2;
        margin-bottom: 0;
      }
      .loan-type {
        margin-bottom: 0;
      }

      .group-right {
        flex-shrink: 0;
      }
    }

    @media @min769 {
      .group-thumbnail {
        min-height: 9.3rem;
        position: relative;
        width: 14.5rem;

        .theme-loan-state {
          left: unset;
          right: 0;
        }
      }

      .group-content {
        display: flex;
        flex: 1;
        padding: 2em 2em 2em 4em;
      }

      .group-left,
      .group-right {
        align-items: center;
        display: flex;
      }

      .group-left {
        margin-right: 1em;
      }
    }

    @media @max768 {
      .panel-body {
        flex-direction: column;
      }

      .panel-icon {
        align-items: center;
        background: @secondary;
        display: flex;
        height: 2rem;
        justify-content: center;
        padding: 0;
        text-align: center;
        top: 0;
        transform: translateY(50%);
        width: 2rem;

        svg {
          color: @white;
          font-size: 1.5rem;
        }
      }
    }
  }
}

@primary-color: #012a64;@primary: #012a64;@secondary: #056e47;@tertiary: #056e47;@tertiary-dark: #056e47;@quaternary: #24B3D5;@grey-light: #E3E3E3;@grey-white: #F4F4F4;@alert: #FDE6F1;@menu-color: #056e47;@menu-active-color: #033d28;@heading-text-color: #012a64;@text-color: #5d5d5d;@input-placeholder-color: #5d5d5d;@link-color: #24B3D5;@link-hover-color: #44C0D6;@2column-bg: #012a64;@step-icon-active: #012a64;@border-color: #E3E3E3;@icon-bg-color: #E3E3E3;@grey-bg-color: #F4F4F4;@grey-bg-darker-color: #E3E3E3;@heading-1-size: 2.375rem;@heading-2-size: 2.375rem;@heading-3-size: 1.8rem;@heading-4-size: 1.5rem;@heading-5-size: 1.3rem;@font-size-stand: 1.0625rem;@font-size-md: 1.125rem;@font-size-sm: 0.875rem;@font-size-xs: 0.875rem;@font-size-xxs: 0.75rem;@font-size-label: 0.875rem;@font-size-button: 1.0625rem;@font-size-step-title: 1.25rem;@font-size-input: 0.875rem;@heading-font: 'Avenir LT Black', sans-serif;@text-font: 'Avenir LT Light', sans-serif;@label-font: 'Avenir LT Black', sans-serif;@step-title-font: 'Avenir LT Black', sans-serif;@button-font: 'Avenir LT Black', sans-serif;@small-font: 'Avenir LT Light', sans-serif;@menu-font: 'Avenir LT Light', sans-serif;@badge-line-height-0: 2.4;@badge-line-height: 2rem;