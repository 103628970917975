// Breakpoints
@min0: ~"(min-width: 0px)";

@max480: ~"(max-width: 480px)";
@min481: ~"(min-width: 481px)";

@max768: ~"(max-width: 768px)";
@min769: ~"(min-width: 769px)";

@max990: ~"(max-width: 990px)";
@min991: ~"(min-width: 991px)";

@max1199: ~"(max-width: 1199px)";
@min1200: ~"(min-width: 1200px)";

@fa-font-path: "../../src/Fonts/fontawesome";

@gap-xs: 0.2rem;
@gap-sm: 0.5rem;
@gap-base: 1rem; //16
@gap-medium: 1.5625rem; //25
@gap-big: 2.5rem; //40
@gap-xbig: 3.4rem; //54.4

@section-gap: @gap-xbig;
@section-gap-m: 2rem;
@title-bottom: 1.2rem;
@grid-gutter-width: 1rem;
@grid-columns: 12;
@formItem-m: 1.3rem;
@formItem-m-mobile: 1rem;
@gutter: 1.5rem;
@step-icon: 1.875rem;
@badge-line-height-0: 2.2;
@badge-line-height: 1.875rem;
@action-icon-size: 2.375rem;
@action-icon-size-small: 1.75rem;
@padding-panel: 1.5rem;
@floating-amount: -0.15rem;
@form-height: 3rem;
@switch-height: 2rem;

.section-gap {
  margin: @section-gap 0;
}
