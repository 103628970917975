@import "./_brand-colors";
@import "./Mixins/_filter-style";
@import "./Mixins/_container";
@import "./Mixins/_text-colors";
@import "./_cover-markups";

.container {
  .container();
}

ul {
  list-style-type: disc;
  padding-inline-start: 1em;
  li {
    line-height: 1.5;
  }
}

input::placeholder {
  color: @placeholder-color !important;
}

.drawer-button {
  .ant-alert {
    margin-bottom: @gap-base;
    width: 100%;
  }
  > a {
    &:hover {
      text-decoration: none;
    }
  }
}

.page-inner {
  > .wrapper {
    > div:not(:last-child) {
      margin-bottom: @section-gap;
    }
  }
}
