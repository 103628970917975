@import "../../Less/Mixins/_text-style";
@import "../../Less/Mixins/_box-shadow-style";
@import "../../Less/Mixins/_make-col";
@import "../../Less/Mixins/_make-row";
@import "../../Less/_default-variables";

.theme-card {
  margin-bottom: @gap-medium;
  .ant-card-head {
    .ant-card-head-title {
      font-size: @heading-3-size;
      line-height: 1.3;
      white-space: normal;
      .text-style(heading);

      > * {
        margin: 0;
      }
    }
  }

  .ant-card-body {
    .text-style(body);

    padding: @padding-panel;

    @media @max480 {
      padding: @gap-sm;
    }
  }

  .ant-card-actions {
    border-top: none;
    padding: @padding-panel;

    > li {
      margin: 0;
    }
  }

  &:not(.ant-card-type-dashboard) {
    border: none !important;
    margin-left: auto;
    margin-right: auto;
    .box-shadow(default);

    .ant-card-head {
      background: @grey-bg-color;
    }

    .ant-card-extra {
      display: flex;
      padding: 0 !important;

      .ant-btn {
        margin-right: 0.5rem;

        &:last-child {
          margin-right: 0;
        }
      }
    }
  }

  &.ant-card-type-dashboard {
    border: 1px solid @border-dark-color;

    .ant-card-head {
      border-color: @border-color;
      min-height: auto;

      .ant-card-head-title {
        .theme-title {
          font-size: inherit !important;
        }
      }
    }
  }
}

@primary-color: #012a64;@primary: #012a64;@secondary: #056e47;@tertiary: #056e47;@tertiary-dark: #056e47;@quaternary: #24B3D5;@grey-light: #E3E3E3;@grey-white: #F4F4F4;@alert: #FDE6F1;@menu-color: #056e47;@menu-active-color: #033d28;@heading-text-color: #012a64;@text-color: #5d5d5d;@input-placeholder-color: #5d5d5d;@link-color: #24B3D5;@link-hover-color: #44C0D6;@2column-bg: #012a64;@step-icon-active: #012a64;@border-color: #E3E3E3;@icon-bg-color: #E3E3E3;@grey-bg-color: #F4F4F4;@grey-bg-darker-color: #E3E3E3;@heading-1-size: 2.375rem;@heading-2-size: 2.375rem;@heading-3-size: 1.8rem;@heading-4-size: 1.5rem;@heading-5-size: 1.3rem;@font-size-stand: 1.0625rem;@font-size-md: 1.125rem;@font-size-sm: 0.875rem;@font-size-xs: 0.875rem;@font-size-xxs: 0.75rem;@font-size-label: 0.875rem;@font-size-button: 1.0625rem;@font-size-step-title: 1.25rem;@font-size-input: 0.875rem;@heading-font: 'Avenir LT Black', sans-serif;@text-font: 'Avenir LT Light', sans-serif;@label-font: 'Avenir LT Black', sans-serif;@step-title-font: 'Avenir LT Black', sans-serif;@button-font: 'Avenir LT Black', sans-serif;@small-font: 'Avenir LT Light', sans-serif;@menu-font: 'Avenir LT Light', sans-serif;@badge-line-height-0: 2.4;@badge-line-height: 2rem;