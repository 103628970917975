@import "../../../Less/_default-variables";
@import "../../../Less/_typography";
@import "../../../Less/_brand-colors";

.theme-table {
  table {
    table-layout: fixed !important;
  }

  .ant-table-filter-trigger-container {
    &:hover {
      background: transparent;
    }

    &-open {
      background: rgba(255, 255, 255, 0.2);
    }
  }
  .ant-table-cell:not(.ant-table-row-expand-icon-cell) {
    border-bottom: 0 !important;
    color: @text-color;
    font-family: @label-font;
    font-size: @font-size-label;
    line-height: 1.2;
    padding: 0.5rem;
    text-transform: initial !important;

    &:last-child {
      border-right: 0 !important;
    }

    @media @max480 {
      font-size: @font-size-xxs;
      word-break: break-word;

      .ant-btn-sm {
        font-size: @font-size-xxs !important;
      }
    }
  }

  .ant-table-thead {
    .ant-table-cell {
      background: @tertiary !important;
      border: none;
      color: @white !important;
      padding: 1rem 0.5rem;
      vertical-align: initial;
    }
  }

  .ant-table-tbody {
    > .ant-table-row:not(:last-child) {
      > .ant-table-cell {
        border-bottom: 1px dashed @border-color !important;
      }
    }

    .ant-table-cell {
      border-bottom: 0 !important;
      vertical-align: initial;
    }

    @media @max480 {
      > .ant-table-row {
        > .ant-table-cell {
          border-bottom: 1px solid @border-color !important;
        }
      }
    }
  }

  .ant-table-summary {
    background: @grey-white !important;

    .ant-table-cell {
      font-family: @heavy-font !important;

      &:last-child {
        text-align: right !important;
      }
    }
  }

  .theme-button {
    min-width: unset;
  }

  .ant-table-expanded-row {
    > .ant-table-cell {
      background: @grey-bg-color;
    }
  }
  .ant-table-row-expand-icon-cell {

    @expand-icon-size: 1.5625rem;

    .ant-table-row-expand-icon {
      height: @expand-icon-size;
      transform: scale(1);
      width: @expand-icon-size;

      &::before {
        top: calc(@expand-icon-size / 2 - 1px);
      }
      &::after {
        left: calc(@expand-icon-size / 2 - 1px);
      }
      &.ant-table-row-expand-icon-collapsed {
        &::before {
          transform: translateY(-50%);
        }
        &::after {
          transform: translateX(-50%);
        }
      }

      @media @max480 {
        @expand-icon-size-sm: 1rem;

        height: @expand-icon-size-sm;
        width: @expand-icon-size-sm;

        &::before {
          top: calc(@expand-icon-size-sm / 2 - 1px);
        }
        &::after {
          left: calc(@expand-icon-size-sm / 2 - 1px);
        }
      }

      &.ant-table-row-expand-icon-expanded {
        &::before {
          opacity: 0;
        }
      }
    }
  }
}

.ant-table-filter-dropdown {
  > .ant-dropdown-menu {
    border-radius: 0 !important;

    [type="checkbox"]:checked + .ant-checkbox-inner {
      background: @primary;
    }
  }
}
