@import "../../../Less/Mixins/_radio-checkbox-style";
@import "../../../Less/_brand-colors";
@import "../../../Less/Mixins/_make-row";
@import "../../../Less/Mixins/_generate-columns";
@import "../../../Less/Mixins/_faicon";

.theme-radio-group,
.ant-radio-group {
  display: flex !important;
  flex-wrap: wrap;

  .ant-radio-wrapper {
    align-items: center;
    display: flex;
    height: 100%;
    margin-bottom: 0.5rem;
    margin-right: 2rem;
    white-space: pre-wrap;

    &.ant-radio-wrapper-checked {
      border-color: @secondary !important;
    }

    &.verified {
      .ant-radio {
        position: relative;
        &::before {
          color: @secondary;
          content: "";
          font-size: 1.2rem;
          position: absolute;
          top: 0;
          .faicon(@fa-var-check, fas);
        }

        input {
          visibility: hidden;
        }

        .ant-radio-inner {
          visibility: hidden;
        }
      }

      .ant-radio-checked::after {
        border: none !important;
      }
    }
  }
  .ant-radio {
    .radio-style();
  }

  &.box-style {
    .make-row();
    @iterations: 12;
    .generate-columns(@iterations);

    .entity-radio,
    .theme-radio {
      border: 1px solid @border-color;
      border-radius: 0.2rem;
      margin: 0;
      padding: 1.5rem 1rem;
      width: 100% !important;
    }

    &.has-1-columns {
      @media @min769 {
        max-width: 50% !important;
      }

      @media @min991 {
        max-width: 25% !important;
      }
    }
  }
}

.theme-radio-group-inline {
  label.ant-radio-wrapper {
    width: auto;
  }
}

@primary-color: #012a64;@primary: #012a64;@secondary: #056e47;@tertiary: #056e47;@tertiary-dark: #056e47;@quaternary: #24B3D5;@grey-light: #E3E3E3;@grey-white: #F4F4F4;@alert: #FDE6F1;@menu-color: #056e47;@menu-active-color: #033d28;@heading-text-color: #012a64;@text-color: #5d5d5d;@input-placeholder-color: #5d5d5d;@link-color: #24B3D5;@link-hover-color: #44C0D6;@2column-bg: #012a64;@step-icon-active: #012a64;@border-color: #E3E3E3;@icon-bg-color: #E3E3E3;@grey-bg-color: #F4F4F4;@grey-bg-darker-color: #E3E3E3;@heading-1-size: 2.375rem;@heading-2-size: 2.375rem;@heading-3-size: 1.8rem;@heading-4-size: 1.5rem;@heading-5-size: 1.3rem;@font-size-stand: 1.0625rem;@font-size-md: 1.125rem;@font-size-sm: 0.875rem;@font-size-xs: 0.875rem;@font-size-xxs: 0.75rem;@font-size-label: 0.875rem;@font-size-button: 1.0625rem;@font-size-step-title: 1.25rem;@font-size-input: 0.875rem;@heading-font: 'Avenir LT Black', sans-serif;@text-font: 'Avenir LT Light', sans-serif;@label-font: 'Avenir LT Black', sans-serif;@step-title-font: 'Avenir LT Black', sans-serif;@button-font: 'Avenir LT Black', sans-serif;@small-font: 'Avenir LT Light', sans-serif;@menu-font: 'Avenir LT Light', sans-serif;@badge-line-height-0: 2.4;@badge-line-height: 2rem;