@import "../_default-variables";

.vertical-page-layout() {
  align-items: center;
  display: flex;
  flex-direction: column;
  height: 100% !important;
  justify-content: center;
  text-align: center;

  > * {
    display: block;
    width: 100%;
  }

  .theme-text-wrapper {
    margin-bottom: @title-bottom;
  }

  .ant-image-img {
    display: block;
    margin: auto;
    margin-bottom: @gap-xbig;
    max-width: 13rem;
  }

  @media @max990 {
    justify-content: flex-start;
    padding-bottom: @section-gap;
    padding-top: @section-gap;
  }
}
