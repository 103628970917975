@import "_brand-colors";
@import "_default-variables";

//Colors
@error-color: @danger !important;
@success-color: @success !important;

// Buttons
@btn-default-color: white;

//Link
@link-color: @secondary;

// Inputs
@radio-dot-color: @secondary;
@radio-button-bg: @secondary;
@radio-button-checked-bg: @secondary;

@border-radius-base: 0.1875rem;

// Form
@label-required-color: @link-color !important;
@input-placeholder-color: @placeholder-color;
@input-color: @text-color;
@input-border-color: trasparent;
@input-height-base: auto;

.ant-row {
  &.theme-card-inner-wrapper {
    margin-left: -0.5rem;
    margin-right: -0.5rem;

    &:last-child {
      .theme-field-inner {
        margin-bottom: 0 !important;
      }
    }

    .ant-col:empty {
      display: none !important;
    }

    .theme-field-inner {
      padding: 0.5rem;
    }

    @media @max480 {
      .theme-field-inner {
        margin: 0 !important;
      }
    }
  }
}
