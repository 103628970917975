@import "./_text-style";

.comment-style () {
  .text-style(body);

  background: @grey-bg-color;
  padding: 1rem;

  &:empty {
    display: none;
  }
}
