@import "../_brand-colors";

.text-colors() {
  danger: @danger;
  success: @success;
  warning: @warning;
}

each(.text-colors(), {
  .color-@{key} {
    color: @value !important;
  }
});
