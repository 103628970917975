.badge-style() {
  background: @secondary;
  border-radius: 1rem !important;
  border-width: 0 !important;
  color: @white !important;
  display: inline-block;
  font-size: @font-size-badge !important;
  margin: 0.2rem !important;
  min-width: unset !important;
  padding: 0.3rem 1rem !important;
  text-transform: capitalize !important;
}
